<template>
  <Modal @close="$emit('close')">
    <template #header>
      <div class="w-full text-center p-2">
        <h1 class="text-2xl">
          {{ $props.recordId ? 'Update' : 'Create' }} Record
        </h1>
      </div>
    </template>

    <template #body>
      <div class="min-w-md flex flex-col my-2 p-4 max-h-[30rem] overflow-y-auto w-full max-w-md">
        <div>
          <p class="font-semibold">
            {{ plant.fleet }}
          </p>

          <p class="w-72 truncate">
            {{ plant.description }}
          </p>
        </div>

        <form
          action=""
          class="flex flex-col w-full space-y-2"
          @submit.prevent="saveRecord"
        >
          <fieldset class="flex flex-col space-y-2">
            <legend class="flex justify-between font-bold w-full">
              <span>Time Details</span>
            </legend>

            <div
              v-if="!record"
              class="flex items-center space-x-2"
            >
              <p>
                Night shift
              </p>

              <label class="relative items-center cursor-pointer font-normal my-1">
                <input
                  v-model="toggleNightShift"
                  type="checkbox"
                  class="peer sr-only"
                >

                <div class="peer btn--switch-blue peer-checked:bg-ccm-blue-100 w-9 h-5 after:w-4 after:h-4" />
              </label>
            </div>

            <label v-if="!props.recordId">
              Date
              <AppInput
                v-model="recordForm.form.day"
                name="day"
                type="date"
                data-test="day"
                :error="recordForm.getErrors('day')"
                class="form-input"
              />
            </label>

            <template v-if="!plant.smu_tracking">
              <label>
                Start Time
                <AppInput
                  v-model="recordForm.form.start_at"
                  name="start_at"
                  type="time"
                  data-test="start_at"
                  :error="recordForm.getErrors('start_at')"
                  class="form-input"
                />
              </label>

              <label>
                End Time
                <AppInput
                  v-model="recordForm.form.end_at"
                  name="end_at"
                  :type="endAtType"
                  data-test="end_at"
                  :error="recordForm.getErrors('end_at')"
                  class="form-input"
                />
              </label>
            </template>

            <template v-else>
              <label>
                SMU Start
                <AppInput
                  v-model.number="recordForm.form.smu_start"
                  name="smu_start"
                  type="number"
                  data-test="smu_start"
                  :error="recordForm.getErrors('smu_start')"
                  class="form-input"
                />
              </label>

              <label>
                SMU for record
                <AppInput
                  v-model.number="recordForm.form.smu"
                  name="smu"
                  type="number"
                  data-test="smu_end"
                  :error="recordForm.getErrors('smu_end')"
                  class="form-input"
                />
              </label>
            </template>

            <div
              v-if="plant?.smu_tracking"
              class="w-full flex justify-between"
            >
              <p class="font-bold">
                SMU End
              </p>

              <p>{{ recordForm.form.smu_end }}</p>
            </div>

            <div
              v-else
              class="w-full flex justify-between"
            >
              <p class="font-bold">
                Total Time
              </p>

              <p>{{ totalTime > 0 ? `${totalTime.toFixed(1)} hrs` : '' }}</p>
            </div>

            <div class="flex space-x-4 w-full">
              <label class="w-1/2">
                Breakdown (hr)
                <AppInput
                  v-model.number="recordForm.form.breakdown"
                  name="breakdown"
                  type="number"
                  step="0.5"
                  min="0"
                  data-test="breakdown"
                  :max="project?.plant_standard_shift"
                  :error="recordForm.getErrors('breakdown')"
                  class="form-input w-full"
                />
              </label>

              <label class="w-1/2">
                Standdown (hr)
                <AppInput
                  v-model.number="recordForm.form.standdown"
                  name="standdown"
                  type="number"
                  step="0.5"
                  min="0"
                  data-test="standdown"
                  :max="project?.plant_standard_shift"
                  :error="recordForm.getErrors('standdown')"
                  class="form-input"
                />
              </label>
            </div>
          </fieldset>

          <div class="w-full flex justify-between">
            <p class="font-bold">
              Operating
            </p>

            <p>
              {{
                (Math.max(totalTime - recordForm.form.breakdown - recordForm.form.standdown, 0)).toFixed(1)
              }} hrs
            </p>
          </div>

          <fieldset class="flex flex-col space-y-2 max-w-md">
            <legend class="font-bold">
              Location Details
            </legend>

            <label>
              Area

              <AppSelect
                v-model="recordForm.form.area"
                data-test="area"
                name="area"
                :options="project?.areas"
                :error="recordForm.getErrors('area')"
                class="form-input"
              />
            </label>

            <label>
              Element

              <AppSelect
                v-model="recordForm.form.element"
                data-test="element"
                name="element"
                :options="project?.elements"
                :error="recordForm.getErrors('element')"
                class="form-input"
              />
            </label>

            <label>
              Activity

              <AppSelect
                v-model="recordForm.form.activity"
                data-test="activity"
                name="activity"
                :options="project?.activities"
                :error="recordForm.getErrors('activity')"
                class="form-input"
              />
            </label>

            <label>
              WBS Code

              <AppSelect
                v-model.number="recordForm.form.wbs_code_id"
                data-test="wbs_code"
                name="wbs_code_id"
                :options="project?.codes.map((code) => ({ value: code.id, label: code.code }))"
                :error="recordForm.getErrors('wbs_code_id')"
                class="form-input"
              />
            </label>

            <label>
              WBS Description

              <AppSelect
                v-model.number="recordForm.form.wbs_code_id"
                :options="project?.codes.map((code) => ({ value: code.id, label: code.description }))"
                name="wbs_description"
                data-test="wbs_description"
                class="form-input"
              />
            </label>
          </fieldset>

          <fieldset
            v-if="project?.orders.length"
            class="flex flex-col space-y-2"
          >
            <legend class="font-bold">
              Variation/Delay Details
            </legend>

            <label class="w-full">
              Event Number

              <div class="flex w-full">
                <AppSelect
                  v-model.number="recordForm.form.variation_order_id"
                  :options="project?.orders.map((order) => ({ value: order.id, label: order.code }))"
                  name="vo_number"
                  data-test="vo_number"
                  :error="recordForm.getErrors('variation_order_id')"
                  class="just form-input pr-10 flex-1"
                  optional
                />

                <button
                  v-if="recordForm.form.variation_order_id"
                  class="group"
                  type="button"
                  @click.prevent="recordForm.resetData(['variation_order_id'])"
                >
                  <Icon
                    name="close"
                    class="h-6 w-6 text-red-600 m-1"
                  />
                </button>
              </div>
            </label>

            <label>
              Event Description

              <AppSelect
                v-model.number="recordForm.form.variation_order_id"
                class="form-input pr-10"
                :options="project?.orders.map((order) => ({ value: order.id, label: order.description }))"
                name="vo_description"
                data-test="vo_description"
                :error="recordForm.getErrors('variation_order_id')"
                optional
              />
            </label>
          </fieldset>
        </form>

        <div v-if="record?.comments.length">
          <hr class="my-2">

          <h3 class="font-bold">
            Comments
          </h3>

          <div
            v-for="comment in record.comments"
            :key="comment.id"
          >
            <p class="font-semibold">
              {{ comment.creator?.name }}
              <span class="text-sm font-normal">
                {{ comment.createdAt.toLocaleString('en-AU', {
                  hour12: true,
                  hour: "numeric",
                  minute: "numeric",
                  day: "numeric",
                  month: 'numeric',
                  year: 'numeric'

                }) }}
              </span>
            </p>
            {{ comment.body }}
          </div>
        </div>
      </div>
    </template>

    <template #footer>
      <div class="flex p-4">
        <button
          v-if="props.recordId"
          type="button"
          class="btn btn--tertiary-blue"
          @click.prevent="deleteRecord"
        >
          Delete
        </button>

        <button
          v-if="props.recordId"
          type="button"
          class="btn btn--tertiary-blue"
          data-test="split-record"
          @click.prevent="$emit('split')"
        >
          Split
        </button>

        <div class="ml-auto">
          <button
            type="button"
            class="btn btn--secondary-blue"
            @click.prevent="$emit('close')"
          >
            Cancel
          </button>

          <button
            type="button"
            class="btn btn--primary-blue ml-2"
            data-test="save-record"
            :disabled="!recordForm.form.day"
            @click="saveRecord"
          >
            {{ $props.recordId ? 'Update' : 'Create' }}
          </button>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { useFormData } from '@/composables/useFormData';
import { useStoreApiAction } from '@/composables/useStoreApiAction';
import { dateAsYMD, dateForInput, hoursToDate, timeForInput, timeZone } from '@/helpers';
import Plant from '@/models/Plant';
import { usePlantDailyRecordsStore } from '@/store/plantDailyRecord';
import { useProjectsStore } from '@/store/projects';
import Swal from 'sweetalert2';
import { computed, nextTick, ref, watch } from 'vue';
import Modal from '../Modal.vue';

const props = defineProps<{
  projectId: Id;
  recordId?: Id;
  plant: Plant;
}>();

const emits = defineEmits<{
  (e: 'close');
  (e: 'split');
}>();

const projectsStore = useProjectsStore();

const project = computed(() => {
  return projectsStore.project;
});

const recordForm = useFormData({
  day: dateAsYMD(new Date()),
  start_at: '',
  end_at: '',
  smu_start: 0,
  smu_end: 0,
  smu: 0,
  breakdown: 0,
  standdown: 0,
  quantity: 0,
  area: '',
  element: '',
  activity: '',
  wbs_code_id: undefined,
  off_hire: false,
  comment: '',
  variation_order_id: undefined,
  time_zone: timeZone,
});

watch(() => recordForm.form.smu, (newValue) => {
  if(newValue) {
    recordForm.form.smu_end = newValue + recordForm.form.smu_start;
  } else {
    recordForm.form.smu_end = recordForm.form.smu_start;
  }
}, { immediate: true });

const plantDailyRecordsStore = usePlantDailyRecordsStore();
const endAtType = ref<'time' | 'datetime-local'>('time');

watch(endAtType, (newValue, oldValue) => {
  recordForm.form.end_at = '';
});

const record = computed(() => {
  return plantDailyRecordsStore.models.with('comments', (query) => {
    query.with('creator');
  }).with('plant').find(props.recordId);
});

watch(() => props.recordId, (newValue) => {
  if(newValue) {
    plantDailyRecordsStore.fetchRecord(newValue).then(() => {
      plantDailyRecordsStore.models.with('comments').load([record.value]);
    });
  }
}, { immediate: true });

watch(record, (newValue) => {
  if(newValue) {
    if(!props.plant.smu_tracking) {
      recordForm.form.start_at = timeForInput(newValue.startAt);

      if(newValue.startAt.hour >= 17) {
        endAtType.value = 'datetime-local';

        nextTick(() => {
          recordForm.form.end_at = dateForInput(newValue.endAt);
        });
      } else {
        endAtType.value = 'time';

        nextTick(() => {
          recordForm.form.end_at = timeForInput(newValue.endAt);
        });
      }
    }

    recordForm.setData(record.value, [
      'start_at',
      'end_at',
      'time_zone',
    ]);

    if(props.plant.smu_tracking) {
      recordForm.form.smu = recordForm.form.smu_end - recordForm.form.smu_start;
    }
  } else if(!props.plant.smu_tracking && !props.recordId) {
    recordForm.form.start_at = project.value?.shift_start_at;

    const end = new Date(project.value?.shiftStartAt);

    end.setHours(end.getHours() + project.value?.labour_standard_shift);

    if(hoursToDate(project.value?.shift_start_at).getHours() >= 17) {
      endAtType.value = 'datetime-local';

      nextTick(() => {
        recordForm.form.end_at = dateForInput(end);
      });
    } else {
      endAtType.value = 'time';

      nextTick(() => {
        recordForm.form.end_at = timeForInput(end);
      });
    }
  }
}, { immediate: true });

const futureRecord = ref(false);

watch(() => recordForm.form.day, (newValue) => {
  if(newValue) {
    const dayAsDate = new Date(newValue);

    if(!isNaN(dayAsDate.getTime()) && dayAsDate.getTime() > new Date().getTime()) {
      futureRecord.value = true;
    } else {
      futureRecord.value = false;
    }
  }
});

watch(() => recordForm.form.start_at, (newValue, oldValue) => {
  if(newValue) {
    const hourSplit = newValue.split(':');
    const hour = parseInt(hourSplit[0]);

    if(hour >= 17) {
      endAtType.value = 'datetime-local';

      nextTick(() => {
        if(!oldValue && !recordForm.form.end_at) {
          const endAt = new Date(recordForm.form.day);
          const hourSplit = newValue.split(':');

          endAt.setHours(parseInt(hourSplit[0]));
          endAt.setMinutes(parseInt(hourSplit[1]));
          recordForm.form.end_at = dateForInput(endAt);
        }
      });
    } else {
      endAtType.value = 'time';

      nextTick(() => {
        if(!oldValue && !recordForm.form.end_at) {
          const endAt = new Date(recordForm.form.day);
          const hourSplit = newValue.split(':');

          endAt.setHours(parseInt(hourSplit[0]));
          endAt.setMinutes(parseInt(hourSplit[1]));
          recordForm.form.end_at = timeForInput(endAt);
        }
      });
    }
  }
});

const totalTime = computed(() => {
  if(recordForm.form.start_at && recordForm.form.end_at) {
    let endAt: Date;

    if(endAtType.value === 'time') {
      if(recordForm.form.day) {
        endAt = new Date(`${recordForm.form.day} ${recordForm.form.end_at}`);
      } else {
        endAt = new Date();

        endAt
          .setHours(
            ...(recordForm.form.end_at.split(':').map((value) => parseInt(value)) as [number, number]),
          );
      }
    } else {
      endAt = new Date(recordForm.form.end_at);
    }

    let startAt: Date;

    if(recordForm.form.day) {
      startAt = new Date(`${recordForm.form.day} ${recordForm.form.start_at}`);
    } else {
      startAt = new Date();

      startAt
        .setHours(
          ...(recordForm.form.start_at.split(':').map((value) => parseInt(value)) as [number, number]),
        );
    }

    const timeDiff = Math.abs(startAt.getTime() - endAt.getTime()) / 3600000;

    return (Math.round(timeDiff * 2) / 2);
  }

  if(recordForm.form.smu_start && recordForm.form.smu_end) {
    const timeDiff = Math.abs(recordForm.form.smu_start - recordForm.form.smu_end);

    return timeDiff;
  }

  return 0;
});

const createRecordAction = useStoreApiAction(plantDailyRecordsStore.createRecord);
const updateRecordAction = useStoreApiAction(plantDailyRecordsStore.updateRecord);

const saveRecord = () => {
  recordForm.resetErrors();

  if(props.recordId) {
    Swal.fire({
      text: 'Why are you making this change?',
      icon: 'info',
      input: 'text',
      showConfirmButton: true,
      showCancelButton: true,
    }).then((result) => {
      if(result.isConfirmed) {
        recordForm.form.comment = result.value;

        updateRecordAction.request(record.value.id, recordForm.form, { day: recordForm.form.day }).then(() => {
          emits('close');
        }).catch((error) => {
          console.log(error);

          recordForm.setErrors(error.data);
        });
      }
    });
  } else {
    if(futureRecord.value) {
      Swal.fire({
        icon: 'warning',
        title: 'This is a future date record',
        text: 'Are you sure you want to save this record?',
        showCancelButton: true,
      }).then((result) => {
        if(result.isConfirmed) {
          createRecordAction.request(props.plant.id, recordForm.form).then(() => {
            Swal.fire({
              icon: 'success',
              title: 'Record Saved.',
            });

            emits('close');
          }).catch((error) => {
            recordForm.setErrors(error.data);
          });
        }
      });
    } else {
      console.log(recordForm.form);

      createRecordAction.request(props.plant.id, recordForm.form).then(() => {
        Swal.fire({
          icon: 'success',
          title: 'Record Saved.',
        });

        emits('close');
      }).catch((error) => {
        console.log(error);

        recordForm.setErrors(error.data);
      });
    }
  }
};

const previousRecord = computed(() => {
  return plantDailyRecordsStore.models
    .where('day', dateAsYMD(new Date(recordForm.form.day)))
    .where('plant_id', props.plant.id)
    .orderBy('id', 'desc')
    .first() ||
    plantDailyRecordsStore.models
      .where('plant_id', props.plant.id)
      .orderBy('id', 'desc')
      .first();
});

watch(previousRecord, (newValue) => {
  if(newValue && !props.recordId) {
    recordForm.form.area = newValue.area;
    recordForm.form.element = newValue.element;
    recordForm.form.wbs_code_id = newValue.wbs_code_id;
    recordForm.form.activity = newValue.activity;

    if(props.plant.smu_tracking) {
      recordForm.form.smu_start = newValue.smu_end;
      recordForm.form.smu_end = newValue.smu_end + projectsStore.project?.plant_standard_shift;
      recordForm.form.smu = projectsStore.project?.plant_standard_shift;
    }
  }
}, { immediate: true });

const deleteRecordAction = useStoreApiAction(plantDailyRecordsStore.deleteRecord);

const deleteRecord = () => {
  Swal.fire({
    icon: 'warning',
    title: 'Delete Record?',
    text: `Are you sure you want to delete the record for ${new Date(record.value.day).toLocaleDateString()}?`,
    showCancelButton: true,
    showConfirmButton: true,
  }).then((result) => {
    if(result.isConfirmed) {
      deleteRecordAction.request(props.recordId).then(() => {
        Swal.fire({
          icon: 'success',
          text: 'Record deleted',
        }).then(() => {
          emits('close');
        });
      }).catch((error) => {
        if(error?.status === 422) {
          Swal.fire({
            icon: 'error',
            text: `Unable to delete record as it has been approved.`,
          });
        }
      });
    }
  });
};

const toggleNightShift = ref(false);

watch(toggleNightShift, (newValue) => {
  if(project.value) {
    const shiftStart = newValue ? project.value.night_shift_start_at : project.value.shift_start_at;
    const end = new Date(newValue ? project.value.nightShiftStartAt : project.value.shiftStartAt);

    end.setHours(end.getHours() + project.value.labour_standard_shift);
    recordForm.form.start_at = newValue ? project.value.night_shift_start_at : project.value.shift_start_at;

    nextTick(() => {
      if(hoursToDate(shiftStart).getHours() >= 17) {
        recordForm.form.end_at = dateForInput(end);
      } else {
        recordForm.form.end_at = timeForInput(end);
      }
    });
  }
});
</script>

<style scoped></style>
