<template>
  <div class="w-full">
    <div class="flex p-2 w-full bg-blue-900">
      <h1 class="text-3xl text-white font-semibold">
        Labour Record
      </h1>

      <p class="text-white absolute right-2">
        {{ day.toLocaleDateString() }}
      </p>
    </div>

    <div class="flex w-full justify-start bg-blue-900 p-2">
      <h2 class="text-base text-white">
        {{ labour?.first_name }} {{ labour?.last_name }}
      </h2>
    </div>

    <div class="w-[90%] mx-auto mt-8">
      <div class="flex flex-col w-full my-2 p-5 bg-white rounded-lg">
        <form
          action=""
          class="w-full flex flex-col space-y-4"
          @submit.prevent="saveRecord"
        >
          <fieldset class="flex flex-col space-y-4">
            <legend class="flex justify-between font-bold w-full">
              <span>Time Details</span>

              <span>{{ totalTime ? `${totalTime.toFixed(1)} hrs` : '' }}</span>
            </legend>

            <div
              v-if="!record"
              class="flex items-center space-x-2"
            >
              <p>
                Night shift
              </p>

              <label class="relative items-center cursor-pointer font-normal my-1">
                <input
                  v-model="toggleNightShift"
                  type="checkbox"
                  class="peer sr-only"
                >

                <div class="peer btn--switch-blue peer-checked:bg-ccm-blue-100 w-9 h-5 after:w-4 after:h-4" />
              </label>
            </div>

            <label>
              Start Time
              <AppInput
                v-model="recordForm.form.start_at"
                class="form-input"
                name="start_at"
                type="time"
                data-test="start_at"
                :error="recordForm.getErrors('start_at')"
              />
            </label>

            <label>
              End Time
              <AppInput
                v-model="recordForm.form.end_at"
                class="form-input"
                name="end_at"
                :type="endAtType"
                data-test="end_at"
                :error="recordForm.getErrors('end_at')"
              />
            </label>

            <label
              v-if="showMinorBreak || minorBreakMarked"
              class="flex space-x-2 items-center"
              :class="{ 'opacity-50': minorBreakMarked }"
            >
              <div class="flex flex-col">
                <p>Smoke Break (15min)</p>

                <p class="text-sm">{{ minorBreakMarked ? 'Marked on another record' : '' }}</p>

                <span
                  v-if="recordForm.getErrors('minor_break')"
                  class="text-red-600 text-sm font-semibold"
                >{{ recordForm.getErrors('minor_break') }}</span>
              </div>

              <input
                v-if="minorBreakMarked"
                type="checkbox"
                disabled
                checked
              >

              <div
                v-else
                class="flex flex-col"
              >
                <input
                  v-model="recordForm.form.minor_break"
                  type="checkbox"
                  name="minor_break"
                  data-test="minor_break"
                >
              </div>
            </label>

            <label
              v-if="showMajorBreak || majorBreakMarked"
              class="flex space-x-2 items-center"
              :class="{ 'opacity-50': majorBreakMarked }"
            >
              <div class="flex flex-col">
                <p>Lunch Break (30min)</p>

                <p class="text-sm">{{ majorBreakMarked ? 'Marked on another record' : '' }}</p>

                <span
                  v-if="recordForm.getErrors('major_break')"
                  class="text-red-600 text-sm font-semibold"
                >{{ recordForm.getErrors('major_break') }}</span>
              </div>

              <input
                v-if="majorBreakMarked"
                type="checkbox"
                disabled
                checked
              >

              <div
                v-else
                class="flex flex-col"
              >

                <input
                  v-model="recordForm.form.major_break"
                  type="checkbox"
                  name="major_break"
                  data-test="major_break"
                >
              </div>
            </label>

            <label>
              Apply Public Holiday Rate

              <input
                v-model="recordForm.form.is_public_holiday"
                type="checkbox"
                name="is_public_holiday"
                data-test="is_public_holiday"
              >
            </label>
          </fieldset>

          <fieldset class="flex flex-col space-y-4">
            <legend class="font-bold">
              Location Details
            </legend>

            <label>
              Area

              <AppSelect
                v-model="recordForm.form.area"
                data-test="area"
                class="form-input"
                name="area"
                :options="project?.areas"
                :error="recordForm.getErrors('area')"
              />

            </label>

            <label>
              Element

              <AppSelect
                v-model="recordForm.form.element"
                data-test="element"
                class="form-input"
                name="element"
                :options="project?.elements"
                :error="recordForm.getErrors('element')"
              />
            </label>

            <label>
              Activity

              <AppSelect
                v-model="recordForm.form.activity"
                data-test="activity"
                class="form-input"
                name="activity"
                :options="project?.activities"
                :error="recordForm.getErrors('activity')"
              />
            </label>

            <label>
              WBS Code

              <AppSelect
                v-model.number="recordForm.form.wbs_code_id"
                class="form-input"
                data-test="wbs_code"
                name="wbs_code_id"
                :options="project?.codes.map((code) => ({ value: code.id, label: code.code }))"
                :error="recordForm.getErrors('wbs_code_id')"
              />
            </label>

            <label>
              WBS Description

              <AppSelect
                v-model.number="recordForm.form.wbs_code_id"
                class="form-input"
                :options="project?.codes.map((code) => ({ value: code.id, label: code.description }))"
                name="wbs_description"
                data-test="wbs_description"
              />
            </label>
          </fieldset>

          <fieldset
            v-if="project?.orders.length"
            class="flex flex-col space-y-4"
          >
            <legend class="font-bold">
              Variation/Delay Details
            </legend>

            <label>
              <span>
                Event Number
              </span>

              <div class="flex space-x-2">
                <div class="flex-1">
                  <AppSelect
                    v-model.number="recordForm.form.variation_order_id"
                    class="form-input"
                    :options="project?.orders.map((order) => ({ value: order.id, label: order.code }))"
                    name="vo_number"
                    data-test="vo_number"
                    :error="recordForm.getErrors('variation_order_id')"
                  />
                </div>

                <button
                  v-if="recordForm.form.variation_order_id"
                  class="group"
                  type="button"
                  @click.prevent="recordForm.resetData(['variation_order_id'])"
                >
                  <Icon
                    name="close"
                    class="h-6 w-6 text-red-600 m-1"
                  />
                </button>
              </div>
            </label>

            <label>
              <span>
                Event Description
              </span>

              <div>
                <div>
                  <AppSelect
                    v-model.number="recordForm.form.variation_order_id"
                    class="form-input"
                    :options="project?.orders.map((order) => ({ value: order.id, label: order.description }))"
                    name="vo_description"
                    data-test="vo_description"
                    :error="recordForm.getErrors('variation_order_id')"
                  />
                </div>
              </div>
            </label>
          </fieldset>

          <div class="flex justify-end pt-10">
            <button
              type="button"
              class="btn btn--secondary-blue"
              @click.prevent="router.push({ name: 'MobileLabourDashboard', query: { day: dateAsYMD(day) } })"
            >
              Cancel
            </button>

            <p
              v-if="record?.approved_at"
              class="text-center"
            >
              Record has been approved and cannot be modified
            </p>

            <button
              v-else
              type="submit"
              class="btn btn--primary-blue ml-2"
              data-test="submit_form"
            >
              {{ record ? 'Update' : 'Save' }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useFormData } from '@/composables/useFormData';
import { useStoreApiAction } from '@/composables/useStoreApiAction';
import { dateForDateInput, timeZone } from '@/helpers';
import { dateAsYMD, dateForInput, hoursToDate, timeForInput } from '@/helpers';
import Labour from '@/models/Labour';
import Project from '@/models/Project';
import { useLabourDailyRecordStore } from '@/store/labourDailyRecord';
import { useLaboursStore } from '@/store/labours';
import { useNavStore } from '@/store/nav';
import { useProjectsStore } from '@/store/projects';
import Swal from 'sweetalert2';
import { computed, ComputedRef, nextTick, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const props = defineProps<{
  projectId: string;
  labourId: string;
  recordId?: string;
}>();

const projectsStore = useProjectsStore();
const laboursStore = useLaboursStore();

laboursStore.fetchLabour(props.labourId);

const labour = computed(() => {
  return laboursStore.models.query().with('records', (query) => {
    query.whereId(parseInt(props.recordId));
  }).find(parseInt(props.labourId));
});

const project = computed(() => {
  return projectsStore.project;
});

const record = computed(() => {
  return labour.value?.records.length ? labour.value?.records[0] : undefined;
});

const queryParams = useRoute().query.day as string;
const day = ref(queryParams ? new Date(queryParams) : new Date());

const recordForm = useFormData({
  day: dateAsYMD(day.value),
  start_at: '',
  end_at: '',
  area: '',
  element: '',
  wbs_code_id: undefined,
  activity: '',
  comment: '',
  variation_order_id: undefined,
  is_public_holiday: false,
  minor_break: false,
  major_break: false,
  time_zone: timeZone,
});

const endAtType = ref<'time' | 'datetime-local'>('time');

watch(endAtType, () => {
  recordForm.form.end_at = '';
});

const futureRecord = ref(false);

watch(() => recordForm.form.day, (newValue) => {
  if(newValue) {
    const dayAsDate = new Date(newValue);

    if(!isNaN(dayAsDate.getTime()) && dayAsDate.getTime() > new Date().getTime()) {
      futureRecord.value = true;
    } else {
      futureRecord.value = false;
    }
  }
}, { immediate: true });

const totalTime = computed(() => {
  if(recordForm.form.start_at && recordForm.form.end_at) {
    let endAt: Date = undefined;

    if(endAtType.value === 'time') {
      endAt = new Date(`${recordForm.form.day} ${recordForm.form.end_at}`);
    } else {
      endAt = new Date(recordForm.form.end_at);
    }

    const timeDiff = Math.abs(
      new Date(`${recordForm.form.day} ${recordForm.form.start_at}`).getTime() -
        endAt.getTime(),
    ) / 3600000;

    return (Math.round(timeDiff * 2) / 2);
  }

  return undefined;
});

watch(() => recordForm.form.start_at, (newValue, oldValue) => {
  if(newValue) {
    const hourSplit = newValue.split(':');
    const hour = parseInt(hourSplit[0]);

    if(hour >= 17) {
      endAtType.value = 'datetime-local';

      nextTick(() => {
        if(!oldValue && !recordForm.form.end_at) {
          const endAt = new Date(day.value);
          const hourSplit = newValue.split(':');

          endAt.setHours(
            !props.recordId ? parseInt(hourSplit[0]) + project.value.labour_standard_shift : parseInt(hourSplit[0]) + 2,
          );

          endAt.setMinutes(parseInt(hourSplit[1]));
          recordForm.form.end_at = dateForInput(endAt);
        }
      });
    } else {
      endAtType.value = 'time';

      nextTick(() => {
        if(!oldValue && !recordForm.form.end_at) {
          const endAt = new Date(day.value);
          const hourSplit = newValue.split(':');

          endAt.setHours(
            !props.recordId ? parseInt(hourSplit[0]) + project.value.labour_standard_shift : parseInt(hourSplit[0]) + 2,
          );

          endAt.setMinutes(parseInt(hourSplit[1]));
          recordForm.form.end_at = timeForInput(endAt);
        }
      });
    }
  }
});

watch(record, (newValue) => {
  if(newValue) {
    recordForm.form.start_at = timeForInput(newValue.startAt);

    if(newValue.startAt.hour >= 17) {
      endAtType.value = 'datetime-local';

      nextTick(() => {
        recordForm.form.end_at = dateForInput(newValue.endAt);
      });
    } else {
      endAtType.value = 'time';

      nextTick(() => {
        recordForm.form.end_at = timeForInput(newValue.endAt);
      });
    }

    recordForm.setData(newValue, [
      'start_at',
      'end_at',
      'time_zone',
    ]);

    day.value = new Date(newValue.day);
    navStore.backRoute = { name: 'MobileLabourDashboard', query: { day: dateAsYMD(day.value) } };
  }
});

const labourDailyRecordsStore = useLabourDailyRecordStore();
const createRecordAction = useStoreApiAction(labourDailyRecordsStore.createRecord);
const updateRecordAction = useStoreApiAction(labourDailyRecordsStore.updateRecord);
const router = useRouter();

const showMinorBreak = computed(() => {
  let dailyTime = totalTime.value || 0;
  let minorBreak = false;

  if(record.value?.minor_break) {
    return true;
  }

  const records = labourDailyRecordsStore.models
    .where('labour_id', labour.value?.id)
    .where('day', recordForm.form.day)
    .get();

  records.forEach((record) => {
    dailyTime += record.hours;

    if(record.minor_break) {
      minorBreak = record.minor_break;
    }
  });

  return dailyTime > 3 && !minorBreak;
});

const showMajorBreak = computed(() => {
  let dailyTime = totalTime.value || 0;
  let majorBreak = false;

  if(record.value?.major_break) {
    return true;
  }

  const records = labourDailyRecordsStore.models
    .where('labour_id', labour.value?.id)
    .where('day', recordForm.form.day)
    .get();

  records.forEach((record) => {
    dailyTime += record.hours;

    if(record.major_break) {
      majorBreak = record.major_break;
    }
  });

  return dailyTime > 3 && !majorBreak;
});

const saveRecord = () => {
  recordForm.resetErrors();

  if(record.value) {
    Swal.fire({
      text: 'Why are you making this change?',
      icon: 'info',
      input: 'text',
      showConfirmButton: true,
      showCancelButton: true,
    }).then((result) => {
      if(result.isConfirmed) {
        recordForm.form.comment = result.value;

        updateRecordAction.request(record.value.id, recordForm.form, { day: recordForm.form.day }).then(() => {
          router.push({ name: 'MobileLabourDashboard', query: { day: dateAsYMD(day.value) } });
        }).catch((error) => {
          recordForm.setErrors(error.data);
        });
      }
    });
  } else {
    if(futureRecord.value) {
      Swal.fire({
        icon: 'warning',
        title: 'This is a future date record',
        text: 'Are you sure you want to save this record?',
        showCancelButton: true,
      }).then((result) => {
        if(result.isConfirmed) {
          createRecordAction.request(labour.value?.id, recordForm.form).then(() => {
            router.push({ name: 'MobileLabourDashboard', query: { day: dateAsYMD(day.value) } });
          }).catch((error) => {
            recordForm.setErrors(error.data);
          });
        }
      });
    } else {
      createRecordAction.request(labour.value?.id, recordForm.form).then(() => {
        router.push({ name: 'MobileLabourDashboard', query: { day: dateAsYMD(day.value) } });
      }).catch((error) => {
        recordForm.setErrors(error.data);
      });
    }
  }
};

const navStore = useNavStore();

navStore.backRoute = { name: 'MobileLabourDashboard', query: { day: dateAsYMD(day.value) } };

const previousRecord = computed(() => {
  return labourDailyRecordsStore.models
    .where('day', dateAsYMD(day.value))
    .where('labour_id', parseInt(props.labourId))
    .orderBy('id', 'desc')
    .first() ||
    labourDailyRecordsStore.models
      .where('labour_id', parseInt(props.labourId))
      .orderBy('id', 'desc')
      .first();
});

watch(previousRecord, (newValue) => {
  if(newValue && !props.recordId) {
    recordForm.form.area = newValue.area;
    recordForm.form.element = newValue.element;
    recordForm.form.wbs_code_id = newValue.wbs_code_id;
    recordForm.form.activity = newValue.activity;

    if(newValue.day === dateAsYMD(day.value)) {
      recordForm.form.start_at = timeForInput(newValue.end_at);
    }
  }
});

watch(() => [projectsStore.project, labour] as [Project, ComputedRef<Labour>], ([newProject, newLabour]) => {
  const previousRecord = newLabour.value instanceof Labour ?
    labourDailyRecordsStore.models
      .where('day', dateAsYMD(day.value))
      .where('labour_id', labour.value?.id)
      .first() :
    undefined;

  if(!previousRecord && newProject instanceof Project) {
    recordForm.form.start_at = newProject.shift_start_at;
    const end = new Date(newProject.shiftStartAt);

    end.setHours(end.getHours() + newProject.labour_standard_shift);

    if(hoursToDate(newProject.shift_start_at).getHours() >= 17) {
      recordForm.form.end_at = dateForInput(end);
    } else {
      recordForm.form.end_at = timeForInput(end);
    }
  }
}, { immediate: true });

const minorBreakMarked = computed(() => {
  let minorBreak = false;

  const records = labourDailyRecordsStore.models
    .where('labour_id', labour.value?.id)
    .where('day', recordForm.form.day)
    .get();

  records.forEach((labourRecord) => {
    if(labourRecord.minor_break && labourRecord.id !== record.value?.id) {
      minorBreak = labourRecord.minor_break;
    }
  });

  return minorBreak;
});

const majorBreakMarked = computed(() => {
  let majorBreak = false;

  const records = labourDailyRecordsStore.models
    .where('labour_id', labour.value?.id)
    .where('day', recordForm.form.day)
    .get();

  records.forEach((labourRecord) => {
    if(labourRecord.major_break && labourRecord.id !== record.value?.id) {
      majorBreak = labourRecord.major_break;
    }
  });

  return majorBreak;
});

const toggleNightShift = ref(false);

watch(toggleNightShift, (newValue) => {
  if(project.value) {
    const shiftStart = newValue ? project.value.night_shift_start_at : project.value.shift_start_at;
    const end = new Date(newValue ? project.value.nightShiftStartAt : project.value.shiftStartAt);

    end.setHours(end.getHours() + project.value.labour_standard_shift);
    recordForm.form.start_at = newValue ? project.value.night_shift_start_at : project.value.shift_start_at;

    nextTick(() => {
      if(hoursToDate(shiftStart).getHours() >= 17) {
        recordForm.form.end_at = dateForInput(end);
      } else {
        recordForm.form.end_at = timeForInput(end);
      }
    });
  }
});
</script>
